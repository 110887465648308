import React from 'react'
import Container from '../../components/container/container'
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import errorStyles from './error.module.css'
import fonts from '../../styles/fonts.module.css'
import BriefcaseIcon from '../../../static/svgs/briefcase.svg'
import SaunaIcon from '../../../static/svgs/sauna.svg'
import ChampagneIcon from '../../../static/svgs/champagne.svg'
import SearchIcon from '../../../static/svgs/search.svg'
import SEO from '../../components/seo/seo'
import { useIntl, Link, FormattedMessage } from '../../../plugins/gatsby-plugin-intl-custom'

const Error = () => {
  const intl = useIntl()

  return (
    <>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: 'errorPage.contentNotFoundHeading' })} />
      <Navigation />
      <Container>
        <div className={errorStyles.errorWrapper}>
          <div className={errorStyles.errorHeader}>
            <h1 className={fonts.heading1}>
              <FormattedMessage id="errorPage.contentNotFoundHeading" />
            </h1>
            <p className={fonts.paragraph}>
              <FormattedMessage id="errorPage.contentNotFoundParagraph" />
            </p>
          </div>
          <div className={errorStyles.errorLinks}>
            <Link to="/kokoustilat" className={errorStyles.errorLink}>
              <div>
                <BriefcaseIcon alt="Briefcase icon" className={errorStyles.errorLinkIcon} />
                <p className={[fonts.paragraph, fonts.bold, errorStyles.errorLinkText].join(' ')}>
                  <FormattedMessage id="errorPage.meetingRooms" />
                </p>
              </div>
            </Link>
            <Link to="/saunat" className={errorStyles.errorLink}>
              <div>
                <SaunaIcon alt="Sauna icon" className={errorStyles.errorLinkIcon} />
                <p className={[fonts.paragraph, fonts.bold, errorStyles.errorLinkText].join(' ')}>
                  <FormattedMessage id="errorPage.saunaRooms" />
                </p>
              </div>
            </Link>
            <Link to="/juhlatilat" className={errorStyles.errorLink}>
              <div>
                <ChampagneIcon alt="Champagne icon" className={errorStyles.errorLinkIcon} />
                <p className={[fonts.paragraph, fonts.bold, errorStyles.errorLinkText].join(' ')}>
                  <FormattedMessage id="errorPage.partyRooms" />
                </p>
              </div>
            </Link>
            <Link to="/reservation" className={errorStyles.errorLink}>
              <div>
                <SearchIcon alt="Search icon" className={errorStyles.errorLinkIcon} />
                <p className={[fonts.paragraph, fonts.bold, errorStyles.errorLinkText].join(' ')}>
                  <FormattedMessage id="errorPage.searchForReservation" />
                </p>
              </div>
            </Link>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default Error
